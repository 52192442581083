import React from "react"
import styled from "styled-components"
//import ReactGA from "react-ga"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Hero from "../components/CustomerSegmentHero"
import CustomerSegmentValueProps from "../components/CustomerSegmentValueProps"
import CustomerSegmentCarousel from "../components/CustomerSegmentCarousel"
import CustomerSegmentCenterTestingBlurb from "../components/CustomerSegmentCenterTestingBlurb"
import CustomerSegmentQuestion1 from "../components/CustomerSegmentQuestion1"
import CustomerSegmentApp from "../components/CustomerSegmentApp"

import { Ghost } from "../lib/colors"

import Case1 from "../images/case_janet.jpg"
import Case2 from "../images/case_harris.jpg"

import PC1 from "../images/bi-slider1.jpeg";
import PC2 from "../images/bi-slider2.jpeg";
import PC3 from "../images/bi-slider3.jpeg";
import PC4 from "../images/bi-slider4.jpeg";
import PC5 from "../images/bi-slider5.jpeg";

import Screen1 from "../images/bi-mobile-screen1.jpeg";
import Screen2 from "../images/bi-mobile-screen2.jpeg";

import HeroBackground from "../images/epm-hero-bg.jpg"
import EpmDescription from "../images/epm-description.jpg";
import { CX_FEATURES } from "../lib/constants";

import { sizes } from "../lib/layout"


const list = [
  "Financial Planning",
  "reporting as a services",
  "Financial Close & Consolidation Strategy"
]

const arrayOfImages = [Case1, Case2]

const arrayOfQuotes = [
  {
    quote: "business strategies allignment",
    background:
      "At mivors we have found it is important for IT, business and finance to ask if their business strategies are aligned. Too often, the answer to this question is, “We don’t know.” Technology decisions are often made with little understanding of how, if at all, an investment in your finance ecosystem will drive the business forward. Many times, business leaders perceive technology as a cost – an expense they must manage, rather than a vehicle to help the company achieve its financial goals.",
  },
  {
    quote: "End to End Planning and Reporting.",
    background:
      "Configure an all-inclusive planning process with cross module components (revenue, grants, capital, expenses, staffing) Automate customizable reports and analysis Develop “What if” budget planning analysis with versioning Implement complex staff planning capabilities for union processing, vacancies, benefits changes, and detailed compensation calculations Cross module transactions for GL, Budget, and Projects modules Ability to slice and dice and drill into data across all reporting segments Report visualization using dashboards and charts Publication and administration of external books including the ACAFR and Budget Book Incorporate Microsoft doclets (word, excel, or pdf) documents for inclusion of the annual Budget Book and CAFR reporting Incorporate complex capital planning by project and phase K12 student-based budgeting module Complex cash forecasting by day for receipts and expenditures",
  },
]

const EPM = props => {
  const {
    location: { pathname },
  } = props

  return (
    <Layout pathName={pathname}>
      <SEO title="EPM" keywords={[`mivors`, `hyperion`, `epm`]} />
      <Hero
        bg={HeroBackground}
        title="Gain agility and insights to outperform the market with Oracle EPM cloud."
        description="World class enterprise performance management solutions that run in the cloud are now affordable for any size organization. These applications are designed to improve forecasting and budget accuracy, and streamline accounting to close, consolidate and report financial results."
      />
        <CustomerSegmentValueProps
          bgColor="#6138BA"
          features={CX_FEATURES}
        />
        <CustomerSegmentCarousel
          img={EpmDescription}
          title={`<strong>"business strategies allignment"</strong>`}
          description="At mivors we have found it is important for IT, business and finance to ask if their business strategies are aligned. Too often, the answer to this question is, “We don’t know.” Technology decisions are often made with little understanding of how, if at all, an investment in your management and strategy ecosystem will drive the business forward. Many times, business leaders perceive technology as a cost – an expense they must manage, rather than a vehicle to help the company achieve its financial goals."
        />
        <CustomerSegmentQuestion1
          title="increase the speed and accuracy of financial planning, budgeting and forecasting."
          titleMaxWidth={760}
          descriptions={[
            "Oracle’s Enterprise Performance Management (EPM) Cloud provides complete business solutions for financial close processes, operational and resource planning, forecasting, and budgeting. Oracle EPM Cloud is the market’s most complete and connected EPM solution, giving you the ability to align your business strategy with execution. mivors offers a secure, tuned and customized EPM platform for your EPM applications. Our extensive experience in the leading EPM Cloud solutions for Standard and Enterprise Planning & Budgeting Bundle offerings including Financial Consolidation and Close (FCCS) will allow you to adopt highly configurable business processes across the enterprise using industry best practices, prebuilt models and features."
          ]}
        />
        <CustomerSegmentCenterTestingBlurb
          bgColor="#4D24A5"
          title="Empower your finance organization"
          description="Take advantage of low cost, easy to deploy cloud-based solutions like Oracle’s EPM Cloud–a suite of software modules such as Planning & Budget Cloud Service (PBCS), Financial Consolidation & Close Cloud (FCCS) that enable companies of any size to drive predictable performance and report with confidence. We have helped a number of organizations improve financial management using Oracle applications, and are well versed in providing advice and guidance in strategic areas such as:"
          images={[PC1, PC2, PC3, PC4, PC5]}
          content={
            <>
              <p>
                  <ul>
                    {
                      list.map(li => (
                        <li key={li}>{li}</li>
                      ))
                    }
                  </ul>
                  mivors, along with Icloudready Idataworkers gives you everything you need to launch your state of art corporate performance and reporting system.
              </p>
            </>
          }
        />
        <CustomerSegmentApp
          title="Implement your industry specific corporate performance management"
          description="We deliver oracle cloud reporting and epm solutions integrated with Icloud-ready actionable insights suite which enable us to deliver end to end corporate performance and executive insights solution unique to your industry special requirement."
          direction="rtl"
          desktop={true}
          images={[PC1, PC4]}
        />
    </Layout>
  )
}

export default EPM
